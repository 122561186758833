<template>
  <v-container fluid py-0>
    <v-form ref="form" class="py-4" lazy-validation style="max-width: 650px">
      <v-card class="sweeep-dialog">
        <v-card-title>Googleドライブ連携</v-card-title>
        <v-card-text>
          <p>
            Googleドライブにアップロードしたファイルをsweeep
            に自動で取り込むことができます。
          </p>
          <v-switch
            v-show="solutionState !== 'not_installed'"
            v-model="enabled"
            v-tooltip="'オフにするとGoogleドライブ連携を停止することができます'"
            class="px-3 mb-4"
            color="primary"
            hide-details
            label="Googleドライブでの取込みを許可する"
            style="width: 500px"
            @change="switchEnabled"
          />
          <div v-if="solutionState !== 'disabled'">
            <v-btn
              v-show="!showWizard"
              color="primary"
              @click="createWizard"
              v-text="'設定'"
            />
            <div>
              <iframe
                v-show="showWizard"
                ref="solutionWizard"
                width="600"
                height="700"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { AnyflowSDK } from '@/helpers/gdrive/package/lib/index.esm'
import Snackbar from 'Helpers/snackbar/index'
import axios from 'axios'

export default {
  data() {
    return {
      enabled: false,
      solutionId: '',
      solutionState: 'not_installed',
      showWizard: false,
    }
  },
  created() {
    this.solutionId = process.env.VUE_APP_ANYFLOW_SOLUTION_ID
    this.initSDK().then(() => {
      if (this.solutionState === 'enabled') {
        this.createWizard()
      }
    })
  },
  methods: {
    async initSDK() {
      try {
        AnyflowSDK.init(async () => {
          const url = this.$store.getters.apiGdriveJWT

          const res = await axios.get(url, {
            headers: { Authorization: this.$store.getters.getAuthToken },
          })
          const token = res.data.token
          return token
        })
      } catch (e) {
        console.log('sdk_already_initialized')
      }
      await this.getSolution()
    },
    async getSolution() {
      const sdk = AnyflowSDK.instance
      const solution = await sdk.getSolution(this.solutionId)
      this.solutionState = solution.state
      this.enabled = this.solutionState === 'enabled'
    },
    async createWizard() {
      const sdk = AnyflowSDK.instance
      this.showWizard = true
      const iframe = this.$refs.solutionWizard
      const solutionWizard = sdk.createWizard(iframe)
      solutionWizard.load(this.solutionId)

      const listener = (event, solutionId) => {
        if (event === 'loaded') {
          // ソリューションウィザードが読み込まれたとき
        }
        if (event === 'completed') {
          // ソリューションウィザードが完了したとき
          this.getSolution()
          this.createWizard()
          Snackbar.success('連携が完了しました')
        }
        if (event === 'canceled') {
          // ソリューションウィザードがキャンセルされたとき
          this.showWizard = false
          solutionWizard.unload(solutionId)
        }
      }
      solutionWizard.addListener(listener, this.solutionId)
    },
    async enableSDK() {
      const sdk = AnyflowSDK.instance
      await sdk.enableSolution(this.solutionId)
      await this.getSolution()
    },
    async destroySDK() {
      const sdk = AnyflowSDK.instance
      await sdk.disableSolution(this.solutionId)
      this.getSolution()
    },
    async switchEnabled(on) {
      if (on) {
        await this.enableSDK()
        this.createWizard()
      } else {
        this.destroySDK()
      }
    },
  },
}
</script>
